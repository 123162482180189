
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import {
  ref,
  uploadBytes, listAll, getDownloadURL,
} from "firebase/storage";

import { storage } from '../../firebase';
import { v4 } from "uuid";


import { collection, arrayUnion, setDoc, updateDoc, } from "firebase/firestore";

function Brochure78() {
  const [imageUpload, setImageUpload] = useState(null);
  const [imageList, setImageList] = useState([]);

  const imageListRef = ref(storage, "/BROCHURE/78 SUVARNA (MOTORIZED CHARKHA) with SOURONEER (SOLAR WATER HEATING UNIT)/")
  const uploadImage = () => {
    if (imageUpload == null) return;


    const imageRef = ref(storage, `/BROCHURE/78 SUVARNA (MOTORIZED CHARKHA) with SOURONEER (SOLAR WATER HEATING UNIT)/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {




        setImageList((prev) => [...prev, url])
        alert("Image Uploaded Successful");
      })

    });
  };

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  return (
    // <div className="App">

    
<div className="album py-0">

                {imageList.map((url) => {

                  return <a  style={{color:'Darkblue'}} href={url} target="_blank" rel="noopener noreferrer">View/Download</a>;
                })}
              
      </div>
    

  );
}

export default Brochure78;