

import React from 'react'


import { Link } from "react-router-dom";
// import Theskillfoundation from "../images/theskill.jpg";
// import Notice from "../pages/Notice";

// import Bracureolympiad from "../images/Allbrochures.pdf"
import Blink from 'react-blink-text';
import ReactPlayer from "react-player";


import "./Linkimp.css";
import { Button } from "react-bootstrap";
import Directori from './Directori';
import Soilhealth from './Soilhealth';
import Publication from './Publication';
import Biovoltinecpp from './Biovoltinecpp';
import Notice from './Notice';
import Womenhelp from './Womenhelp';
import EventHeld from './EventHeld';
import Admissionpgds from './Admissionpgds';
import Postponement from './Postponement';
import Pgdsmulberry from './Pgdsmulberry';



function Linkimp() {
    return (
        <div>



            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        <div className="col-md-5 mt-3">
                            <div class="card border-priamry text-white mb-3" >
                                <div class="card-header"><h6 style={{ color: "maroon" }} className='mt-2'><center><h4 className="blink" style={{ color: "maroon" }}> IMPORTANT LINK </h4></center></h6></div>
                                <div class="card-body ">
                                    <a href='Directorbenefit' >
                                        <div className="btn-grad15  mb-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </svg> Direct Benifit Transfer </div></a >


                                    <div className="btn-grad15  mb-3">  <Womenhelp /></div>

                                    <div className="d-grid gap-3">
                                        <a className="btn btn-warning btn-sm me-2 mb-2" href="Minutes" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </svg> Rajbhasha Implementation </a>
                                    </div>

                                    {/* <a className="btn btn-warning btn-sm mb-2 me-2 " target="_blank" rel="noopener noreferrer" href={Howtoapply}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> How To Apply</a> */}

                                    <div className="d-grid gap-3">
                                        <a className="btn btn-warning btn-sm me-2 mb-2" href="Success-story" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                        </svg> Success Story</a>
                                    </div>



                                    {/* <a className="btn-grad15 me-2 mb-2" href="#" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg> FAQ</a> */}

                                    <p className="btn-grad15 me-2 mb-2" target="_blank" rel="noopener noreferrer"> <Directori /> </p>

                                    <p className="btn-grad15 me-2 mb-2" target="_blank" rel="noopener noreferrer"> <Publication /> </p>

                                    <a className="btn-grad15 me-2 mb-2" href="scientist-profile" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg> Scientist Profile</a>

                                    <p className="btn-grad15 me-2 mb-2" > <Soilhealth /> </p>

                                    <a className="btn-grad15 me-2 mb-2" href="rti"> RTI</a>
                                    <a className="btn-grad15 me-2 mb-2" href="gallery"> Gallery</a>
                                    <a className="btn-grad15 me-2 mb-2" href="Advertisement-home"> Advertisement</a>
                                    <a className="btn-grad15 me-2 mb-2" href="tenders">  Tender</a>
                                    <a className="btn-grad15 me-2 mb-2" href="about-us">  About Us</a>


                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card text-dark bg-white mb-2">

                                <div style={{ color: "Darkblue" }} className="card-header bg-light mb-1">Notice Board</div>
                                <br />
                                <Notice />
                                <br />
                                <div className="d-flex justify-content-between align-items-center">

                                </div>
                            </div>
                            <div className="card border-success text-center mb-2">
                                <div className="card-header">
                                    EVENTS HELD
                                </div>
                                <div className="card-body">

                                    <EventHeld />
                                </div>
                            </div>
                            <div className="card border-primary text-center">
                                <div className="card-header blink" style={{ color: "maroon" }}>
                                    <b>    LATEST NEWS</b>
                                </div>
                                <div className="card-body">
                                    <marquee scrollamount="1" direction="up">
                                        <p style={{ color: "green" }} className="card-title mb-2"><b> <Admissionpgds /></b></p>
                                        <p style={{ color: "green" }} className="card-text mb-1"><b>  <Postponement /></b></p>

                                        <p style={{ color: "green" }} className="card-text"> <b><Pgdsmulberry /> </b></p>
                                    </marquee>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-4 mt-3">
                            <div class="card border-primary mb-3">
                                <div class="card-header"><center>	Resham Shilp kotha Episode 1</center></div>
                                <div class="card-body text-primary">
                                    <center>
                                        <ReactPlayer className="mb-2" width='300px' height='240px' url="https://www.youtube.com/watch?v=_vl-Ps_AA3k" />
                                    </center>
                                    {/* <center>
  <iframe className="iframe-fluid" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fchhoton.mazumder%2Fposts%2Fpfbid02rynFHSM4H6FiBj3zrqchtMX5P4j89pgbTvnxRRT22virHkYg4oHR6yEuSYDWvJx9l&show_text=true&width=500"   scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </center> */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Linkimp;
